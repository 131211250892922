import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link, graphql } from 'gatsby'

// In an attempt to not pollute my filesystem, I'll be making component in this one file :)

/*Portfolio Section*/
const PortfolioSection = ({post}) => {
    return (
        <Link to={post.slug} state={{logoActive: false}} className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 portfolioSection" style={{backgroundImage: `url(${post.images[0].file.url});`}}>
            <img className="portfolio_cover_photo" src={post.images[0].file.url} style={{position: 'absolute', zIndex: 0, left: 0, top: 0, height: '100vh'}} alt={post.title} />
                <div className="projectContainer">
                    <h3 className="projectTitle">
                        {post.title}
                    </h3>

                    <img src={post.logo.file.url} className="logo" width={post.dimensions[0]} style={{marginBottom: `${post.dimensions[1]}px`}} alt={post.title} />

                    <p className="description">
                        {post.summary.summary}
                    </p>

                </div>
        </Link>
    )
}


const BlogPage = function blog(props) {

    const { data } = props
    return (
        <Layout logoActive={false} >
            <SEO title='Portfolio' description="Here you'll find a few projects I've worked on over the years. These are all Javascript projects that use frameworks like React, Angular, Meteor, Node, and Express.js ." />
            <section className="portfolio_page">
                {data.allContentfulPortfolioPiece.edges.map( (post, i) => (<PortfolioSection key={i} post={post.node} />))}
            </section>
        </Layout>
    )
}

export const pageQuery = graphql`
    query blogIndexQuery {
        
 allContentfulPortfolioPiece(limit: 3) {
    edges {
      node {
        title
        images {
          file {
            url
            contentType
          }
          description
        }
        agency
        description {
          description
        }
        role
        subtitle
        summary {
          summary
        }
        team
        slug
        stack
        logo {
          file {
            url
          }
          description
        }
        dimensions
        id
        content {
          content
        }
      }
    }
  }
    }
`

export default BlogPage
